import ContentBlock from './ContentBlock';
import CtaBlock from './CtaBlock';
import CtaButtons from './CtaButtons';
import PaquetesMenu from './PaquetesMenu';
import PaquetesSubmenu from './PaquetesSubmenu';
import FeaturesBlock from './FeaturesBlock';
import Footer from './Footer';
import Header from './Header';
import HeroBlock from './HeroBlock';
import Menu from './Menu';
import Social from './Social';
import Layout from './Layout';

export {
    ContentBlock,
    CtaBlock,
    CtaButtons,
    PaquetesMenu,
    PaquetesSubmenu,
    FeaturesBlock,
    Footer,
    Header,
    HeroBlock,
    Menu,
    Social,
    Layout
};

export default {
    ContentBlock,
    CtaBlock,
    CtaButtons,
    PaquetesMenu,
    PaquetesSubmenu: PaquetesSubmenu,
    FeaturesBlock,
    Footer,
    Header,
    HeroBlock,
    Menu,
    Social,
    Layout
};
